//封装post和get两个请求
import service from "./service";
import { getToken } from './auth'
// import axios from "axios"
export function get(url, params) {
    const config = {
        method: "get",
        url,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': getToken(),
        }
    }
    if (params) {
        config.params = params//当有参数的时候在congfig中加params
    }
    return service(config)
}
export function post(url, params) {
    const config = {
        method: "post",
        url,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': getToken(),
        }
    }
    if (params) {
        config.data = params
    }
    return service(config)
}
export function put(url, params) {
    const config = {
        method: "put",
        url,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': getToken(),
        }
    }
    if (params) {
        config.data = params
    }
    return service(config)
}
export function login(url, params) {
    const config = {
        method: "post",
        url,
    }
    if (params) {
        config.data = params
    }
    return service(config)
}
export function downFile(url, params) {
    const config = {
        method: "get",
        url,
        responseType: 'blob',
        headers: {
            // 'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': getToken(),
        }
    }
    if (params) {
        config.params = params//当有参数的时候在congfig中加params
    }
    return service(config)
}
// export function downFile(url, parameter) {
//     return axios({
//         url: url,
//         method: "get",
//         params: parameter,
//         responseType: 'blob',
//         timeout: 10 * 60 * 1000,
//         headers: {
//             // 'Content-Type': 'application/json;charset=UTF-8',
//             'X-Access-Token': getToken(),
//         }
//     })
// }
